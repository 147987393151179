:root {
    --primary-colour: #fa955f;
    --primary-colour--dark: #cc6a35;
    --primary-colour--light: #ffdbc7;
    --primary-colour--tint: #fcefe8;
}

.nav-open-false {
    display: none;
}

.nav-open-true {
    display: inline;
}

.nav {
    align-items: center;
    justify-content: space-around;
    height: 100%;

    @media only screen and (min-width: 769px) {
        display: flex;
    }
}

li {
    list-style: none;
}

.nav li > a {
    display: block;
    padding: 20px;
    text-decoration: underline;
    text-decoration-color: white;
    text-underline-offset: 8px;
    transition: 0.4s;

    @media only screen and (min-width: 1025px) {
        padding: 20px 40px;
    }
}

.nav li > a:hover {
    background-color: var(--primary-colour--tint);
    cursor: pointer;
    text-decoration-color: var(--primary-colour--dark);
}

.nav li > a.nav-item-current {
    background-color: var(--primary-colour--light);
    text-decoration-color: var(--primary-colour--dark);
}

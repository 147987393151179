.hamburger {
    display: flex;
    flex-flow: column nowrap;
    height: 2rem;
    justify-content: space-around;
    margin: 20px;
    position: absolute;
    top: 13px;
    width: 2rem;
    z-index: 10;

    @media only screen and (min-width: 769px) {
        display: none;
    }
}

.burger {
    width: 2rem;
    height: 0.25rem;
    border-radius: 10px;
    background-color: black;
    transform-origin: 1px;
    transition: all 0.3s linear;
}

.burger1-open {
    transform: rotate(45deg);
}

.burger2-open {
    opacity: 0;
}

.burger3-open {
    transform: rotate(-45deg);
}